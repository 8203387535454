@import url(./../../../assets/css/style.css);

.home{
    box-sizing: border-box !important;
    width: 100%;
}

/* Menu Celular */
.home .sidenav .btn, .btn-large, .btn-small {
    text-decoration: none;
    color: #fff;
    background-color: var(--color1) !important;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    transition-delay: 0s;
    cursor: pointer;
}

.home .tabs{
    width: 90%;
    margin-bottom: 30px;
}

.tabs .tab a:hover, .tabs .tab a.active {
    background-color: var(--color2transparent2) !important;
    color: var(--color2) !important;
}

.tabs .tab a {
    color: var(--color2transparent) !important;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 24px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: color .28s ease, background-color .28s ease;
    transition-property: color, background-color;
    transition-duration: 0.28s, 0.28s;
    transition-timing-function: ease, ease;
    transition-delay: 0s, 0s;
}

.tabs .tab a:hover, .tabs .tab a.active {
    background-color: transparent;
    color: var(--color2) !important;
}

.tabs .indicator {
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: var(--color2) !important;
    will-change: left, right;
}


.home .row{
    width: 100%;
    box-sizing: border-box !important;
}

/* DASHBOARD */
.home #home .card{
    width: 100%;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.142);
}

/* MOVIMENTAÇOES */ 

.home .container-tab{
    box-sizing: border-box !important;
    width: 100%;
    margin: 0px;
    padding: 0px;
}
.home #movimentacoes{
    
}
.home .collection-item{
    list-style: none;
}
.home #movimentacoes form input[type]{
    box-sizing: border-box;
    padding: var(--padding2);
    font-size: 1em;
}
.home #movimentacoes form  fieldset legend{
    padding: 0px 20px;
    text-align: center;
    font-weight: bold;
}
.home #movimentacoes form > fieldset{
    max-height: 300px;
    overflow-y: scroll;
}
.home #movimentacoes form fieldset  p{
    margin-left: 0px;
    padding-left: 0px;
    justify-content: left;
}
.home #movimentacoes form fieldset  p label{
    text-align: left;
}
.home #movimentacoes form #container-prazo{
    display: none;
}

.home #movimentacoes .btn, .btn-large, .btn-small {
    text-decoration: none;
    color: #fff;
    background-color: var(--color1) !important;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    transition-delay: 0s;
    cursor: pointer;
}
.home #movimentacoes .btn:hover {
    background-color: var(--color2) !important;
}

.home #movimentacoes [type="checkbox"].filled-in:checked+span:not(.lever):after {
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid var(--color2);
    background-color: var(--color2);
    z-index: 0;
}


/* LISTA DE MOVIMENTAÇÕES */ 
.home #movimentacoes .collection-item .avatar{
    text-align: left;
}

.home span.span-valor{
    font-size: 1.2em;
    margin-left: 10px;
    font-weight: bold;
    padding: 5px;
}
.home span.span-descricao{
    font-weight: lighter;
    font-style: italic;
    font-size: 0.6em;
    background-color: var(--color1);
    margin-left: 5px;
    padding: 5px;
    border-radius: 5px;
    color: white;
}
