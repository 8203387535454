@import url(./../../../assets/css/style.css);

.recuperar{
    width: 100%;
    height: 100vh;
    background-image: url(./../../../assets/bgGrandeCeu.jpg);
    background-repeat: no-repeat;
    background-clip: border-box;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.recuperar .row{
    width: 100%;
}
.recuperar .card{
    width: 100%;    
    box-sizing: border-box;
    border-radius: var(--padding1);
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.418);
}

.recuperar .card .card-title{
    font-size: 2em;
    font-weight: bold;
    color: var(--color2);
    font-family: var(--font-title);
}

.recuperar .card form input{
    border: none;
    box-shadow: none;
}
.recuperar .card form input{
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 1em;
    padding: var(--padding2);
}
    
.recuperar .card .card-links{
    padding: 5px;
}
.recuperar .card .card-links a{
    color: var(--color2);
    margin: 0px 10px;
    transition: all ease 0.5s;
}
.recuperar .card .card-links a:hover{
    opacity: 0.5;
}

.recuperar button{
    background-color: var(--color1);
}
.recuperar button:hover{
    background-color: var(--color2);
}