*{
    margin: 0;
    padding: 0;
}
.artigoBlog{
    margin: 0px;
}
.artigoBlog a{
    text-decoration: none;
    color: #000;
}

.artigoBlog .propaganda img{
    margin-top: 20px;
    width: 80%;
    height: 20px;
    visibility: hidden;
}
.artigoBlog .artigo{
    width: 80%;
    margin-left: 10%;
}
.artigoBlog .artigo:hover .title{
    opacity: 1;
}

/*          TITULO      */
.artigoBlog .artigo .title{
    width: 80%;
    margin-left: 10%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
    padding-bottom: 10px;
    
}
.artigoBlog .artigo .title h3{
    text-align: left;
    font-size: 40pt;
    font-weight: bold;padding: -20px;
}
.artigoBlog .artigo .title p{
    text-align: left;
    font-size: 16pt;
}
.artigoBlog .artigo .title h5{
    text-align: left;
    font-size: 12pt;
    font-weight: bold;
}
.artigoBlog .artigo .title h5 .shared{
    text-align: center;
    font-size: 12pt;
    margin-left: 60%;
}
.artigoBlog .artigo .title h5 .shared{
    margin-left: 65%;
}
.artigoBlog .artigo .title h5 a {
    margin-left: 65%;
    background: none;
    box-shadow: none;
    border: none;
}
.artigoBlog .artigo .title h5 a #share{
    font-size: 18pt;
}
.artigoBlog .artigo .title h5 .btn:hover #share{
    transform: scale(1.5);
}

/*          CORPO       */
.artigoBlog .artigo .corpo{
    width: 60%;
    margin-left: 20%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 30px;
}
.artigoBlog .artigo  h3:hover{
    opacity: 1;
}

.artigoBlog .artigo .corpo p{
    font-size: 16pt;
    text-align: justify;

}
.artigoBlog .artigo .corpo audio {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
}
.artigoBlog .artigo .corpo iframe {
    width: 100%;
    scroll-behavior: smooth;
    margin-top: 5%;
    margin-bottom: 5%;
}
.artigoBlog .artigo .corpo h3 {
    font-size: 20pt;
    font-weight: bold;
    text-align: left;
    margin-top: 2%;
    margin-bottom: 2%;

}
.artigoBlog .artigo .corpo img {
    width: 90%;
    height: auto;
    margin-top: 8%;
    margin-bottom: 8%;
    transform: scale(0.8);
    border-radius: none;
    transition: all ease-in-out 0.5s;
}
.artigoBlog .artigo .corpo img:hover {
    transform: scale(1.1);
    border-radius: 1.5em;
}

.artigoBlog .artigo .corpo table {
    width: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
}
.artigoBlog .artigo .corpo ul {
    display: list-item;
    margin-top: 5%;
    margin-bottom: 5%;
}
.artigoBlog .artigo .corpo ul li{
    width: 80%;
    margin-left: 10%;
    text-align: left;
    list-style-type: disc;
}

/*          TAGS          */
.artigoBlog .artigo .curtir{
    width: 50%;
    margin-left: 25%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}

.artigoBlog .artigo .curtir h3{
    font-size: 14pt;
    
}

.artigoBlog .artigo .curtir a{
    width: auto;
    height: auto;
    margin-right: 10px;
    border-radius: 1.5em;
    background-color: unset;
    border-color: none;
    cursor: pointer;
    transition: all 0.3s;
    
}
.artigoBlog .artigo .curtir .btn:hover #green{
    color: green;    
    transform: scale(1.5);
    
}
.artigoBlog .artigo .curtir .btn:hover  #red{
    color: red;    
    transform: scale(1.5);
    
}
.artigoBlog .artigo .curtir .btn:hover{
    background-color: #fff;
    border-color: #fff;
    box-shadow: none;
    opacity: 0.8;

    
}

.artigoBlog .artigo .curtir i{
    font-size: 22pt;
    margin: 10px;
    color: #ccc;
    transition: all 0.3s;
    transform: scale(0.8);

}


/*          TAGS          */
.artigoBlog .artigo .tags {
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;    
    display: inline-block;
    word-break: break-all;
    
}
.artigoBlog .artigo .tags p{
    padding-bottom: 20px;
    
}

.artigoBlog .artigo .tags .chips{
    font-size: 10pt;
    padding: 10px;
    background-color: #2e012f;
    color: #fff;
    font-weight: bold;
    margin: 5px;
    border-radius: 1.5em;
    text-transform: uppercase;
    cursor: pointer;
    transition: all ease-in-out 0.5s;
}

.artigoBlog .artigo .tags .chips:hover{
    opacity: 0.8;
    background: #5a0230;
}

@media(min-width: 1200px) {
    
}
/* SCREEN TABLET */
@media(min-width: 801px) and (max-width: 1199px) {
    /*          TITULO      */
    .artigoBlog .artigo .title{
        width: 80%;
        margin-left: 10%;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
        padding-bottom: 10px;
        
    }
    .artigoBlog .artigo .title h3{
        font-size: 30pt;
    }  
    .artigoBlog .artigo .title p{
        font-size: 15pt;
    }
    
    .artigoBlog .artigo .title h5{
        font-size: 11pt;
    }
    .artigoBlog .artigo .title h5 .shared{
        margin-left: 65%;
    }
    
        /*          CORPO       */
    .artigoBlog .artigo .corpo{
        width: 70%;
        margin-left: 15%;
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
        padding-bottom: 30px;
        text-indent: 1em;
    }
    .artigoBlog .artigo .corpo p{
        font-size: 15pt;
        text-align: justify;

    }
    .artigoBlog .artigo .corpo p:hover{
        opacity: 1;

    }
    
}
/* SCREEN TABLET */
@media(max-width: 800px) {
    
    /*          TITULO      */
    .artigoBlog .artigo .title{
        width: 90%;
        margin-left: 5%;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
        padding-bottom: 10px;
        
    }
    .artigoBlog .artigo .title h3{
        font-size: 22pt;
    }  
    .artigoBlog .artigo .title p{
        font-size: 13pt;
    }
    
    .artigoBlog .artigo .title h5{
        font-size: 9pt;
    }
    

        /*          CORPO       */
    .artigoBlog .artigo .corpo{
        width: 80%;
        margin-left: 10%;
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
        padding-bottom: 30px;
        text-indent: 1em;
    }
    .artigoBlog .artigo .corpo p{
        font-size: 14pt;
        text-align: justify;

    }

        /*          TAGS          */
    .artigoBlog .artigo .curtir{
        width: 70%;
        margin-left: 15%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;
    }

    .artigoBlog .artigo .curtir h3{
        font-size: 12pt;
        
    }

    .artigoBlog .artigo .curtir a{
        width: auto;
        height: auto;
        margin-right: 10px;
        border-radius: 1.5em;
        background-color: unset;
        border-color: none;
        cursor: pointer;
        transition: all 0.3s;
        
    }
    .artigoBlog .artigo .curtir .btn:hover #green{
        color: green;    
        transform: scale(1.5);
        
    }
    .artigoBlog .artigo .curtir .btn:hover  #red{
        color: red;    
        transform: scale(1.5);
        
    }
    .artigoBlog .artigo .curtir .btn:hover{
        background-color: #fff;
        border-color: #fff;
        box-shadow: none;
        opacity: 0.8;

        
    }

    .artigoBlog .artigo .curtir i{
        font-size: 18pt;
        margin: 10px;
        color: #ccc;
        transition: all 0.3s;
        transform: scale(0.8);

    }


    /*          TAGS          */
    .artigoBlog .artigo .tags {
        width: 90%;
        margin-left: 5%;
        margin-top: 20px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;    
        display: block;
    }
    .artigoBlog .artigo .tags p{
        padding-bottom: 20px;
        
    }

    .artigoBlog .artigo .tags .chips{
        font-size: 8pt;
        padding: 5px;
        background-color: #2e012f;
        color: #fff;
        font-weight: bold;
        margin: 5px;
        border-radius: 1.5em;
        text-transform: uppercase;
    }
}

@media(max-width: 600px){
    /*          TITULO      */
    .artigoBlog .artigo .title{
        width: 95%;
        margin-left: 2.5%;
        border-bottom: 1px solid #ccc;
        margin-bottom: 30px;
        padding-bottom: 10px;
        
    }
    .artigoBlog .artigo .title h3{
        font-size: 18pt;
    }  
    .artigoBlog .artigo .title p{
        font-size: 11pt;
    }
    
    .artigoBlog .artigo .title h5{
        font-size: 8pt;
    }
    .artigoBlog .artigo .title h5 a #share{
        font-size: 14pt;
    }
    
        /*          CORPO       */
    .artigoBlog .artigo .corpo{
        width: 90%;
        margin-left: 5%;
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
        padding-bottom: 30px;
        text-indent: 1em;
    }
    .artigoBlog .artigo .corpo p{
        font-size: 12pt;
        text-align: justify;

    }

        /*          TAGS          */
    .artigoBlog .artigo .curtir{
        width: 80%;
        margin-left: 10%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }

    .artigoBlog .artigo .curtir h3{
        font-size: 10pt;
        
    }

    .artigoBlog .artigo .curtir a{
        width: auto;
        height: auto;
        margin-right: 10px;
        border-radius: 1.5em;
        background-color: unset;
        border-color: none;
        cursor: pointer;
        transition: all 0.3s;
        
    }
    .artigoBlog .artigo .curtir .btn:hover #green{
        color: green;    
        transform: scale(1.5);
        
    }
    .artigoBlog .artigo .curtir .btn:hover  #red{
        color: red;    
        transform: scale(1.5);
        
    }
    .artigoBlog .artigo .curtir .btn:hover{
        background-color: #fff;
        border-color: #fff;
        box-shadow: none;
        opacity: 0.8;

        
    }

    .artigoBlog .artigo .curtir i{
        font-size: 15pt;
        margin: 10px;
        color: #ccc;
        transition: all 0.3s;
        transform: scale(0.8);

    }


    /*          TAGS          */
    .artigoBlog .artigo .tags {
        width: 90%;
        margin-left: 5%;
        margin-top: 20px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;    
        display: block;
    }
    .artigoBlog .artigo .tags p{
        padding-bottom: 20px;
        
    }

    .artigoBlog .artigo .tags .chips{
        font-size: 6pt;
        padding: 3px;
        background-color: #2e012f;
        color: #fff;
        font-weight: bold;
        margin: 5px;
        border-radius: 1.5em;
        text-transform: uppercase;
    }
   

}

@media(max-width: 390px){
    .artigoBlog .artigo .tags .chips{
        font-size: 4pt;
        padding: 2px;
        background-color: #2e012f;
        color: #fff;
        font-weight: bold;
        margin: 5px;
        border-radius: 1.5em;
        text-transform: uppercase;
    }
}