.homeBlog{
    margin: 0px;
    background: rgba(225, 225, 225, 0.4);
    transition: all ease-in-out 2s;
}
.homeBlog a{
    text-decoration: none;
    color: #000;
}

.homeBlog .artigos{
    margin-top: 50px;
    display: inline-flex;
    width: 95%;
    margin-left: 2.5%;
    min-height: 70vh;
    
}

.homeBlog .artigos ul{
    border: none;
}
.homeBlog .artigos h4{
    text-align: center;
    margin-left: 5%;
    font-weight: bold;
    
}
.homeBlog .artigos h5{
    text-align: left;
    margin-left: 5%;
    font-weight: bold;
}


.homeBlog .artigos li{
    padding: 5px;
    margin-top: 10px;
}

.homeBlog .artigos .artigoEsquerdo li{  
    height: auto;
    padding-bottom: 30px; 
    border-bottom: 1px solid #ccc;
    text-align: left;
}

.homeBlog .artigos .artigoEsquerdo{
    width: 62%;
    height: auto;
    margin-right: 2.5%;
}
.homeBlog .artigos .artigoEsquerdo img{
    width: 340px;
    height: auto;
    position: relative;
    margin-right: 10px;
    border-radius: 0.2em;
    max-width: 100%;
	transition: all 0.3s;
    -moz-transform: scale(0.98);
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
}
.homeBlog .artigos .artigoEsquerdo img:hover{
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}
.homeBlog .artigos .artigoEsquerdo .collection-item{
    height: 190px;
}
.homeBlog .artigos .artigoEsquerdo a{
    width: 52%;
    height: 150px;
    position: absolute;
}
.homeBlog .artigos .artigoEsquerdo .title{
    font-size: 16pt;    
    font-weight: bold;
    color: #000;
    height: 300px;
    position: absolute;
}
.homeBlog .artigos .artigoEsquerdo .text{
    font-size: 12pt;
    text-align: justify;
    color: #000;
    position: absolute;
    margin-top: 50px;
}
.homeBlog .artigos .artigoEsquerdo .base{
    width: 50%;
    position: absolute;
    margin-top: 150px;
    cursor: pointer;
    
}
.homeBlog .artigos .artigoEsquerdo .date{
    font-size: 10pt;
    color: #000; 
    margin-right: 30%;
}
.homeBlog .artigos .artigoEsquerdo span .material-icons{
    font-size: 10pt;
    color: #ccc;
    margin-right: 20px;
}
.homeBlog .artigos .artigoEsquerdo span .textIcon{
    font-size: 10pt;
    color: #ccc;
    font-weight: bold; 
    margin-right: 5px;
}
.homeBlog .artigos .artigoEsquerdo .date:hover, .text:hover, .title:hover{
    transition: all 0.3s;
    opacity: 0.8; 
}
.homeBlog .artigos .artigoEsquerdo .base:hover .textIcon{
    color: #5a0230;
    transition: all 0.3s;
}
.homeBlog .artigos .artigoEsquerdo .base:hover .material-icons{
    color: #5a0230;
    transition: all 0.3s;
}

/*      ARTIGO DIREITO   */
.homeBlog .artigos .artigoDireito{
    width: 30%;
    padding: 0px;
    background-color: rgba(245, 245, 245, 0.1); 
    box-shadow: #ccc 3px 3px 2px 2px;
    position: fixed;
    float: right;
    margin-left: 64%;
}



.homeBlog .artigos .artigoDireito ul{  
    max-height: 65vh;
    scroll-behavior: smooth;
    scrollbar-width: thin;      
    scrollbar-color: #2e012f #5a0230;
    border-radius: 20px;
}

.homeBlog .artigos .artigoDireito ul:hover{  
    transition: all ease-in-out 2s;
    max-height: 65vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: thin;        
    scrollbar-color: #2e012f #5a0230;
    border-radius: 20px;
}



.homeBlog .artigos .artigoDireito li{  
    padding-bottom: 20px; 
    
}

.homeBlog .artigos .artigoDireito .more{
    font-size: 14pt;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: -20px;
    position: relative;
    background: rgba(245, 245, 245, 1); 
    z-index: 1;
}

.homeBlog .artigos .artigoDireito .collection-itens{
    height: 100px;
    padding-left: 3%;
}
.homeBlog .artigos .artigoDireito img{
    width: 150px;
    height: auto;
    margin-top: -40px;
    border-radius: 0.1em;
	transition: all 0.3s;
    float: right;
    -moz-transform: scale(0.98);
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    
}
.homeBlog .artigos .artigoDireito img:hover{
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
}


.homeBlog .artigos .artigoDireito .title{
    width: 55%;
    font-size: 10pt;
    text-align: justify;
    font-weight: bold;
    color: #000;
    float: left;
    line-height: 1.3em;
}

.homeBlog .artigos .artigoDireito .date{
    width: 50%;
    font-size: 08pt;
    margin-top: 50px;
    margin-left: -55%;
    color: #000;
    text-align: center;
    float: left;
}

.homeBlog .artigos .artigoDireito .title:hover, .date:hover{
    opacity: 0.8;
}

@media(min-width: 1200px) {
    
}
/* SCREEN TABLET */
@media(min-width: 801px) and (max-width: 1199px) {
    
    
    .homeBlog .artigos .artigoEsquerdo{
        width: 70%;
        height: auto;
        margin-right: 2%;
        margin-left: -3%;
    }    
    .homeBlog .artigos .artigoEsquerdo img{
        width: 40%;
        height: auto;
    }
    .homeBlog .artigos .artigoEsquerdo img:hover{
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
    }      
    .homeBlog .artigos .artigoEsquerdo .collection-item{
        height: 170px;
    }       
    .homeBlog .artigos .artigoEsquerdo .title{
        font-size: 90%; 
        position: absolute;
    }   
    .homeBlog .artigos .artigoEsquerdo .text{
        font-size: 80%;
        margin-top: 40px;
    }    
    .homeBlog .artigos .artigoEsquerdo .base{
        width: 50%;
        position: absolute;
        margin-top: 125px;
        
    }
    
    /*      ARTIGO DIREITO   */
    .homeBlog .artigos .artigoDireito .collection-itens{
        height: 150px;
        padding-left: 1%;
    }
    .homeBlog .artigos .artigoDireito img{
        width: 45%;
        height: auto;
        margin-top: -40px;
        border-radius: 0.1em;
        transition: all 0.3s;
        float: right;
        -moz-transform: scale(0.98);
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        
    }
    .homeBlog .artigos .artigoDireito img:hover{
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    
    
    .homeBlog .artigos .artigoDireito .title{
        width: 53%;
        font-size: 65%;
        text-align: justify;
        font-weight: bold;
        color: #000;
        float: left;
        line-height: 1.3em;
    }
    
    .homeBlog .artigos .artigoDireito .date{
        width: 50%;
        font-size: 08pt;
        margin-top: 15%;
        margin-left: -55%;
        color: #000;
        text-align: center;
        float: left;
    }
    
}
/* SCREEN TABLET */
@media(max-width: 800px) {
    .homeBlog .artigos .artigoEsquerdo{
        width: 70%;
        height: auto;
        margin-right: 2%;
        margin-left: -3%;
    }
    
    .homeBlog .artigos .artigoEsquerdo img{
        width: 100%;
        height: auto;
    }

    .homeBlog .artigos .artigoEsquerdo img:hover{
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    
    
    .homeBlog .artigos .artigoEsquerdo .collection-item{
        width: 100%;
        height: 400px;
    }
    
    
    .homeBlog .artigos .artigoEsquerdo .title{
        width: 180%;
        font-size: 80%; 
        margin-top: 100%;
        margin-left: -188%;
    }
    
    .homeBlog .artigos .artigoEsquerdo .text{
        width: 180%;
        font-size: 80%; 
        margin-top: 109%;
        margin-left: -188%;
    }
    
    .homeBlog .artigos .artigoEsquerdo .base{
        width: 150%;
        font-size: 80%; 
        margin-top: 77%;
        margin-left: -97.5%;
        position: absolute;
        
    }

    /*      ARTIGO DIREITO   */
    .homeBlog .artigos .artigoDireito{        
        background-color: rgba(245, 245, 245, 0.1); 
        box-shadow: #ccc 1px 1px 1px 1px;
    }
    
    .homeBlog .artigos .artigoDireito li{  
        padding-top: 0px; 
        padding-bottom: 0px;
        background-color: rgba(245, 245, 245, 0.2);
    }

    .homeBlog .artigos .artigoDireito .collection-itens{
        height: auto;
        padding-top: 20px;
        padding-bottom: 00px;
        padding-left: 3%;
    }
    .homeBlog .artigos .artigoDireito img{
        display: none;;
        
    }
    .homeBlog .artigos .artigoDireito img:hover{
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    
    
    .homeBlog .artigos .artigoDireito .title{
        width: 96%;
        font-size: 65%;
        text-align: justify;
        font-weight: bold;
        color: #000;
        float: left;
        line-height: 1.5em;
    }
    
    .homeBlog .artigos .artigoDireito .date{
        width: 100%;
        font-size: 05pt;
        margin-top: 5%;
        margin-left: 0%;
        color: #000;
        text-align: center;
        
    }
}

@media(max-width: 600px){

    .homeBlog .artigos{ 
        display: block;
        
    }
    .homeBlog .artigos .artigoEsquerdo{ 
        width: 95%;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
    
    .homeBlog .artigos .artigoEsquerdo li{
        height: 500px;
        padding-bottom: 30px;
        text-overflow: ellipsis;
    }
    .homeBlog .artigos .artigoEsquerdo img{
        width: 100%;
        height: auto;
        margin-right: 10px;
        border-radius: 0.2em;
        max-width: 100%;
        transition: all 0.3s;
        -moz-transform: scale(0.98);
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        position: relative;
    }
    .homeBlog .artigos .artigoEsquerdo .collection-item{
        height: 400px;
    }

    .homeBlog .artigos .artigoEsquerdo .title{
        font-size: 12pt;    
        font-weight: bold;
        color: #000;
        height: 30px;
        text-overflow: ellipsis;
    }
    
    .homeBlog .artigos .artigoEsquerdo .text{
        width: 180%;
        font-size: 90%; 
        margin-top: 129%;
        margin-left: -188%;
    }
    
    .homeBlog .artigos .artigoEsquerdo .base{
        width: 140%;
        font-size: 80%; 
        margin-top: 100%;
        margin-left: -97.5%;
        position: absolute;
        
    }

    /*      ARTIGO DIREITO   */
    .homeBlog .artigos .artigoDireito{
        display: none;
        visibility: hidden;
    }

}

@media(max-width: 405px){
   
}