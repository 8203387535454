@import url(./../../../assets/css/style.css);

.register small{
    font-size: 0.6em;
}
.register{
    width: 100%;
    height: 100vh;
    background-image: url(./../../../assets/bgGrandeCeu.jpg);
    background-repeat: no-repeat;
    background-clip: border-box;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.register .row{
    width: 100%;
}
.register .card{
    width: 100%;
    height: inherit;
    border-radius: 10px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.26);
}

.register .card .card-title{
    font-size: 2em;
    font-weight: bold;
    color: var(--color2);
    font-family: var(--font-title);
}

.register .card form input{
    border: none;
    box-shadow: none;
}
.register .card form input{
    border: 1px solid black;
    border-radius: 10px;
    font-size: 1em;
    padding: var(--padding2);
    box-sizing: border-box;
}

.register .card .card-links{
    padding: 5px;
}
.register .card .card-links a{
    color: var(--color2);
    margin: 0px 10px;
    transition: all ease 0.5s;
}
.register .card .card-links a:hover{
    opacity: 0.5;
}

.register button{
    background-color: var(--color1);
}
.register button:hover{
    background-color: var(--color2);
}