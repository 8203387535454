:root{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    --color1: #2e012f;
    --color2: #5a0230;
    --color3: #b0002f;
    --color4: #fe202f;

    
    --color2transparent1: #5a022f85;
    --color3transparent1: #b0002f6e;
    --color4transparent1: #fe202f64;

    --color2transparent2: #5a022f1f;
    --color3transparent2: #b0002f1e;
    --color4transparent2: #fe202f18;


    --font-title: 'Courier New', Courier, monospace;
    --font-normal: Arial, Helvetica, sans-serif;
    --font-destaque: cursive;

    --padding1: 10px;
    --padding2: 05px;
}

body{
    width: 100%;
    font-size: 10px;
    font-family: var(--font-normal);
}

@media(min-width: 650px){
    body{
        font-size: 12px;
    }
}
@media(min-width: 850px){
    body{
        font-size: 14px;
    }
}
@media(min-width: 1000px){
    body{
        font-size: 16px;
    }
}