*{
    margin: 0;
    padding: 0;
}
ul li{
    text-decoration: none;
}
a{
    text-decoration: none;
    color: #ffffff;
}
#menu nav div{
    background: #2e012f;
    height: 80px;
}
/* Nav Bar */
#menu nav div div div img{
    margin-top: 0px;
    width: 120px;
    height: auto;
}

#menu .nav-content{
    width: 100%;
    padding-top: 80px;
    height: 115px;
    background: rgba(90, 02, 48, 1);
}

#menu .nav-content p{
    text-align: right;
    color: #ffffff;
    font-size: 14pt;
    padding: 2px;
    animation: typing 10s  alternate-reverse infinite;
}

@keyframes typing {
    from { width: 18% }
    to { width: 100% }
  }

/* Menu 
nav div div ul li{
    font-size: 18pt;
    text-transform: uppercase;
}
nav div div ul li a:hover{
    transition:all 0.5s;
    color: #813372;
}
/* Menu Dropdown 
div #aluno{
    margin-top: 60px;
    background: #062f4f;
}
div #aluno a {
    color: #ffffff;
}
div #aluno a:hover{
    transition:all 0.5s;
    color: #813372;
}*/
@media(min-width: 1200px) {
   
}
/* SCREEN TABLET */
@media(min-width: 801px) and (max-width: 1199px) {
    
}
/* SCREEN TABLET */
@media(max-width: 800px) {
    
}

@media(max-width: 600px){
    #menu nav div{
        background: #2e012f;
        height: 50px;
    }

    #menu nav div div div img{
        margin-top: 5px;
        width: 70px;
        height: auto;
    }


}

@media(max-width: 405px){
   
}