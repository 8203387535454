@import url('./../../assets/css/style.css');
.menuDash ul li{
    text-decoration: none;
}
.menuDash a{
    text-decoration: none;
    color: #ffffff;
}
.menuDash #menuDash nav div{
    background: #2e012f;
}
/* Nav Bar */
.menuDash #menuDash nav img{
    margin-top: -30px;
    width: 120px;
    height: auto;
    transform: scale(1);
}

.menuDash #menu .nav-content{
    width: 100%;
    padding-top: 80px;
    height: 115px;
    background: rgba(90, 02, 48, 1);
}

.menuDash #menu .nav-content p{
    text-align: right;
    color: #ffffff;
    font-size: 14pt;
    padding: 2px;
    animation: typing 10s  alternate-reverse infinite;
}
.menuDash .btn, .btn-large, .btn-small {
    text-decoration: none;
    color: #fff;
    background-color: var(--color1) !important;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    transition-delay: 0s;
    cursor: pointer;
}

@keyframes typing {
    from { width: 18% }
    to { width: 100% }
  }

/* Menu 
nav div div ul li{
    font-size: 18pt;
    text-transform: uppercase;
}
nav div div ul li a:hover{
    transition:all 0.5s;
    color: #813372;
}
/* Menu Dropdown 
div #aluno{
    margin-top: 60px;
    background: #062f4f;
}
div #aluno a {
    color: #ffffff;
}
div #aluno a:hover{
    transition:all 0.5s;
    color: #813372;
}*/
@media(min-width: 1200px) {
   
}
/* SCREEN TABLET */
@media(min-width: 801px) and (max-width: 1199px) {
    
}
/* SCREEN TABLET */
@media(max-width: 800px) {
    
}

@media(max-width: 600px){
    .menuDash #menu nav div{
        background: #2e012f;
        height: 50px;
    }

    .menuDash #menu nav div div div img{
        margin-top: 5px;
        width: 70px;
        height: auto;
    }


}

@media(max-width: 405px){
   
}