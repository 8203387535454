@import url(./../../../assets/css/style.css);

.login{
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-image: url(./../../../assets/bgGrandeCeu.jpg);
    background-repeat: no-repeat;
    background-clip: border-box;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login .row{
    width: 100%;
}
.login .card{
    width: 100%;
    max-height: auto;
    border-radius: 10px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.26);
}

.login .card .card-title{
    font-size: 2em;
    font-weight: bold;
    color: var(--color2);
    font-family: var(--font-title);
}

.login .card form input{
    border: none;
    box-shadow: none;
}
.login .card form input{
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 10px;
    padding: var(--padding2);
    font-size: 1em;
}

input.invalid[type=password]:not(.browser-default){
    border: 1px solid black !important;
    box-shadow:none!important;
}

input.valid:not([type]), input.valid:not([type]):focus, input.valid[type=text]:not(.browser-default), input.valid[type=email]:not(.browser-default):focus, input.valid[type=password]:not(.browser-default), input.valid[type=password]:not(.browser-default):focus, input.valid[type=email]:not(.browser-default), input.valid[type=email]:not(.browser-default):focus, input.valid[type=url]:not(.browser-default), input.valid[type=url]:not(.browser-default):focus, input.valid[type=time]:not(.browser-default), input.valid[type=time]:not(.browser-default):focus, input.valid[type=date]:not(.browser-default), input.valid[type=date]:not(.browser-default):focus, input.valid[type=datetime]:not(.browser-default), input:not(.browser-default):focus:not([readonly]), input.valid[type=date]:not(.browser-default){
    border: 1px solid green !important;
    box-shadow:none!important;
}

input.invalid:not([type]), input.invalid:not([type]):focus, input.invalid[type=email]:not(.browser-default), input.invalid[type=email]:not(.browser-default):focus, input.invalid[type=password]:not(.browser-default), input.invalid[type=date]:not(.browser-default){    
    border: 1px solid var(--color4) !important;
    box-shadow:none!important;
}
.select-wrapper input.select-dropdown:focus {
    border: 1px solid green !important;
}
.dropdown-content li>a, .dropdown-content li>span {
    font-size: 1em !important;
    color: var(--color1) !important;
    display: block;
    line-height: 22px;
    padding: 14px 16px;
}

.login .card .card-links{
    padding: 5px;
}

.login .card .card-links a{
    color: var(--color2);
    margin: 0px 10px;
    transition: all ease 0.5s;
}

.login .card .card-links a:hover{
    opacity: 0.5;
}

.login button{
    background-color: var(--color1);
    transition: all ease o.5s;
}

.login button:hover{
    background-color: var(--color2);
}